import React, { useState } from 'react';
import { Add, Delete } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGetUsersQuery, useBlockUserByIdMutation, useDeleteUserByIdMutation } from 'state/api';
import Header from 'components/Header';
import { DataGrid } from '@mui/x-data-grid';
import { message } from 'antd';


// Added refetch
const Users = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useGetUsersQuery();
  const [blockUser] = useBlockUserByIdMutation();
  const [deleteUser] = useDeleteUserByIdMutation();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleBlockUser = async (userId, isBlocked) => {
    try {
      await blockUser(userId).unwrap();
      message.success(isBlocked ? "User unblocked successfully" : "User blocked successfully");
      refetch();
    } catch (error) {
      message.error("Failed to update user status");
    }
  };

  const handleDeleteUser = async () => {
    if (selectedUserId) {
      try {
        await deleteUser(selectedUserId).unwrap();
        message.success("User deleted successfully");
        setOpenDialog(false); // Close the dialog
        refetch();
      } catch (error) {
        message.error("Failed to delete user");
      }
    }
  };

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "username",
      headerName: "Full Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.5,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 0.5,
      renderCell: (params) =>
        params.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3"),
    },
    {
      field: "role",
      headerName: "Role",
      flex: 0.5,
    },
    {
      field: "isBlocked",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="contained"
          color={params.value ? "success" : "error"}
          onClick={() => handleBlockUser(params.row._id, params.value)}
        >
          {params.value ? "Enable" : "Block"}
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          startIcon={<Delete />}
          onClick={() => {
            setSelectedUserId(params.row._id);
            setOpenDialog(true);
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Users" subtitle="Entire list of Users" />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data?.data || []}
          columns={columns}
        />
      </Box>
      <Button
        variant="outlined"
        sx={{
          color: theme.palette.secondary[100],
          borderColor: theme.palette.secondary[300],
        }}
        endIcon={<Add />}
        onClick={() => navigate('/createuser')}
      >
        Add User
      </Button>

      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this user? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteUser}
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Users;
